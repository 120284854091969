<template>
    <div class="root">
        <div class="button material-button">
            <p>
                Free<br />
                Standing<br />
                Banner
            </p>
        </div>
        <div class="size">
            <h2>Select required size below:-</h2>
            <select v-model="size" @change="updateCosts">
                <option value="800">800 x 2000 mm</option>
                <option value="850">850 x 2000 mm</option>
                <option value="1000">1000 x 2000 mm</option>
                <option value="1200">1200 x 2000 mm</option>
                <option value="1500">1500 x 2000 mm</option>
                <option value="2000">2000 x 2000 mm</option>
            </select>
            <button @click="selectImage">Search for an Image</button>
            <div class="cost" v-html="costHTML"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data: function(){
        return{
            width: '',
            height: '2000',
            size: '800',
            cost: 80,
            costHTML: '',
        }
    },
    methods:{
        selectImage: function(){
            
            this.$store.commit( 'setSize', {
                width: this.width,
                height: this.height,
            })

            this.$store.commit( 'setCost', {
                cost: this.cost,
            })
            
            this.$router.push( '/printed-material-images' )
        },
        updateCosts:function(){

            var cost = 0;
            
            switch( this.size )
            {
                case '800' : cost = 80; break;
                case '850' : cost = 85; break;
                case '1000' : cost = 100; break;
                case '1200' : cost = 150; break;
                case '1500' : cost = 190; break;
                case '2000' : cost = 250; break;
            }

            this.width = this.size
            this.cost = cost.toFixed(2)
            
            this.costHTML = '&pound;' + cost.toFixed(2);
        }
    },
    computed:mapGetters([
        'getCurrentProduct', 
    ]),
    created:function(){
        this.$store.commit( 'enablePopularSearches' )
        this.updateCosts()
    },
    watch:{
        width: function(){this.updateCosts()},
        height: function(){this.updateCosts()},
    }
}
</script>

<style scoped>
    .root{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 33rem;
    }

    .button{
        position: relative;
        background: no-repeat;
        background-position: center;
        background-size: cover;
        height: 325px;
        width: 325px;
        border-radius: 1rem;
        box-shadow: 1rem 1rem 2rem rgba(0,0,0,0.5);
    }

    .button p{
        font-size: 3rem;
        font-weight: 700;
        color: rgb( 50, 50, 50 );
        left: 3.5rem;
        top: 2rem;
        position: absolute;
    }

    .material-button{
        background-image: url( /images/icon-banner-stand.jpg );
    }

    .size{
        right: calc(50% - 525px);
        margin-right: 3rem;
        color: white;
    }

    .size h2{
        font-size: 3rem;
        margin: 2rem 0 1rem 0;
    }

    .size label{
        font-size: 2rem;
        display: inline-block;
        width: 7rem;
        margin-right: 2rem;
        margin-top: 2rem;
        text-align: right;
    }

    .size select{
        font-size: 2rem;
        padding: 1rem;
        width: 100%;
    }

    .size .note{
        display: inline;
        font-size: 1.5rem;
        padding: 1rem;
    }

    .size button{
        font-size: 2rem;
        border: none;
        padding: 1rem;
        background-color: white;
        font-weight: 700;
        margin-top: 1rem;
        float: right;
    }

    .cost{
        font-size: 3rem;
        padding: 1.5rem;
    }



</style>